import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//types
import { CLOSE_LIVE_STREAMING_DIALOG } from "../../Store/LiveStreamingVideo/type";

//action
import {
  createLiveStreamingVideo,
  updateLiveStreamingVideo,
} from "../../Store/LiveStreamingVideo/action";

//bashURL
import { baseURL } from "../../util/ServerPath";
import { getFakeUser } from "../../Store/FakeUser/Action";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { permissionError } from "../../util/Alert";

const LiveDialog = (props) => {
  const dispatch = useDispatch();

  const {
    dialog: open,
    dialogData,
    liveStreaming,
  } = useSelector((state) => state.liveStreaming);

  const hasPermission = useSelector((state) => state.admin.user.flag);
  const { user } = useSelector((state) => state.fakeUser);

  useEffect(() => {
    props.getFakeUser(1, 10, "", "ALL", "ALL", "Fake");
  }, []);

  const [mongoId, setMongoId] = useState("");
  const [link, setLink] = useState("");
  const [user_, setUser] = useState("");
  const [video, setVideo] = useState([]);
  const [videoType, setVideoType] = useState(0);
  const [videoPath, setVideoPath] = useState("");
  const [userData, setUserData] = useState([]);

  const [errors, setErrors] = useState({
    video: "",
    user_: "",
    videoPath: "",
  });

  useEffect(() => {
    setUserData(user);
  }, [user]);

  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData?._id);
      setUser(dialogData?.userId?._id);

      //   setLink(dialogData.URL);
      setVideoPath(dialogData?.video);
      setVideoType(dialogData?.videoType);
    }
  }, [dialogData]);

  useEffect(
    () => () => {
      setErrors({
        video: "",
        videoPath: "",
      });
      setMongoId("");
      setUser("");
      //   setLink("");
      setVideoPath(null);
    },
    [open]
  );



  const HandleInputVideo = (e) => {
    setVideo(e.target.files[0]);
    setVideoPath(URL.createObjectURL(e.target.files[0]));
    setErrors((prevErrors) => ({
      ...prevErrors,
      video: "",
    }));
  };

  const closePopup = () => {
    dispatch({ type: CLOSE_LIVE_STREAMING_DIALOG });
  };
  const isLink = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^(ftp|http|https):\/\/[^ "]+$/.test(val);
    return validNumber;
  };
  
  const handleSubmit = () => {
    if (!user_ || !video || !videoPath ){
      const errors = {};
      if (!user_) errors.user_ = "User is require!";

      if (videoType == 0 ? !videoPath : video.length == 0) {
        errors.video = "Video is required!";
      }
      return setErrors({ ...errors });
    } else {

      if(videoType == 0){
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
      if (!urlRegex.test(videoPath)) {
        const errors = { video: "Invalid URL!" };
        return setErrors({ ...errors });
      }
      }

      if (!hasPermission) return permissionError();
          
      const formData = new FormData();

      // formData.append("userId", mongoId);
      formData.append("userId", user_);
      formData.append("videoType", videoType);
      if (videoType == 0 ) {
      
        formData.append("video", videoPath);
      } else {
        formData.append("video", video);
      }

      if (mongoId) {
        props.updateLiveStreamingVideo(mongoId, formData);
      } else {
        props.createLiveStreamingVideo(formData);
      }
      closePopup();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="modal-title font-weight-bold h4">
            {" "}
            LiveStreaming{" "}
          </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="modal-title" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="">
                  <div className="form-group">
                    <label className="text-gray mb-2">User</label>
                    <>
                      <select
                        class="form-select form-control"
                        aria-label="Default select example"
                        value={user_}
                        onChange={(e) => {
                          setUser(e.target.value);
                          if (e.target.value === "Select user") {
                            return setErrors({
                              ...errors,
                              user_: "Please select a user!",
                            });
                          } else {
                            return setErrors({
                              ...errors,
                              user_: "",
                            });
                          }
                        }}
                      >
                        <option value="Select user">Select user</option>
                        {user.map((user) => {
                          return <option value={user._id}>{user.name}</option>;
                        })}
                      </select>
                      {errors.user_ && (
                        <div className="ml-2 mt-1">
                          {errors.user_ && (
                            <div className="pl-1 text__left">
                              <span  style={{ color: "red" }}>
                                {errors.user_}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 d-flex justify-content-start">
                    <label className="mb-2 text-gray">Video Type : </label>
                    <div class="form-check">
                      <input
                        class="form-check-input "
                        type="radio"
                        name="videoType"
                        id="video"
                        value="1"
                        onClick={(e) => {
                          setVideoType(e.target.value);
                        }}
                        checked={videoType == 1 ? true : false}
                      />
                      <label class="form-check-label" for="video">
                        Video
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="videoType"
                        id="linkVideo"
                        value="0"
                        checked={videoType == 0 ? true : false}
                        onClick={(e) => {
                          setVideoType(e.target.value);
                        }}
                      />
                      <label class="form-check-label" for="linkVideo">
                        Link
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className={videoType == 1 ? "col-md-12" : "d-none"}>
                    <div className="form-group ">
                      <label className="mb-2 text-gray">Video</label>
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        accept="video/*"
                        required=""
                        controls
                        // value={}
                        onChange={HandleInputVideo}
                      />
                      {errors.video && (
                        <div className="ml-2 mt-1">
                          {errors.video && (
                            <div className="pl-1 text__left">
                              <span  style={{ color: "red" }}>
                                {errors.video}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {videoPath && (
                        <>
                          <video
                            height="60px"
                            width="60px"
                            alt="app"
                            src={videoPath}
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              marginTop: 10,
                              float: "left",
                              objectFit: "contain",
                              marginRight: 15,
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className={videoType == 0 ? "col-md-12" : "d-none"}>
                    <div className="form-group">
                      <label className="mb-2 text-gray">Video Link</label>
                      <input
                        type="text"
                        className="form-control"
                        required=""
                        placeholder="Video link "
                        value={videoPath}
                        onChange={(e) => {
                          setVideoPath(e.target.value);
                          if (!e.target.value) {
                            return setErrors({
                              ...errors,
                              videoPath: "Video is Required!",
                            });
                          } else {
                            return setErrors({
                              ...errors,
                              videoPath: "",
                            });
                          }
                        }}
                      />
                      {errors.video && (
                        <div className="ml-2 mt-1">
                          {errors.video && (
                            <div className="pl-1 text__left">
                              <span  style={{ color: "red" }}>
                                {errors.video}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                
                <div className={videoPath ? "mt-5 pt-5" : "mt-5"}>
                  <button
                    type="button"
                    className="btn  ml-2 bg-dark-gradient btn-round float-right   icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round ml-1 float-right banner-button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, {
  createLiveStreamingVideo,
  updateLiveStreamingVideo,
  getFakeUser,
})(LiveDialog);
