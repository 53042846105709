import React, { useEffect } from "react";

//css
import "../../bootstrap/css/bootstrap.min.css";
import "../../assets/css/plugins.css";

import "../../assets/css/structure.css";

//js
import "../../bootstrap/js/bootstrap.min";
import "../../bootstrap/js/popper.min";
import "../../assets/js/custom";
import "../../plugins/perfect-scrollbar/perfect-scrollbar.min.js";
import { NavLink as Link } from "react-router-dom";
import { warning } from "../../util/Alert";
import { UNSET_ADMIN } from "../../Store/Admin/admin.type";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import $ from "jquery";

//MUI
import { makeStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  navLink: {
    "&.active": {
      // background: "#C379CF",
      background: "linear-gradient(to right , #d532ee , #e58bf3  )",
      boxShadow: "0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)",
      borderRadius: "6px",
    },
    "&.active span": {
      color: "#fff !important",
      fontWeight: 900,
    },
    "&.active svg": {
      color: "#fff !important",
      fontWeight: 900,
    },
  },
}));

const Sidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const logout = () => {
    const data = warning();
    data
      .then((logout) => {
        if (logout) {
          dispatch({ type: UNSET_ADMIN });
          history.push("/");
        }
      })
      .catch((err) => console.log(err));
  };

  //Collapsed DropDown
  const handleCollapsed = () => {
    $(".dropdown-toggle").toggleClass("collapsed");
    $(".submenu").toggleClass("show");
  };
  $(".dropdown-toggle").click(function () {
    $(".dropdown-toggle").removeClass("active");
    $(this).addClass("active");
    $(".dropdown-toggle").attr("aria-expanded", "false");
  });
  const handleToggle = () => {
    $(".collapse").removeClass("show");
    // $(".collapse").attr("aria-expanded", "false");
  };

  return (
    <>
      <div class="sidebar-wrapper sidebar-theme">
        <nav id="sidebar">
          {/* <div class="shadow-bottom"></div> */}
          <ul
            class="list-unstyled menu-categories ps"
            id="accordionExample"
            // style={{ backgroundColor: "#1F2940" }}
          >
            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/dashboard",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                // aria-expanded="true"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-home"
                  >
                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                  </svg>
                  <span>Dashboard</span>
                </div>
              </Link>
            </li>

            <li className="menu">
              <a href="#app" data-toggle="collapse" className="dropdown-toggle">
                <div style={{ paddingRight: "10px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-user"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                  <span>User</span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-right"
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </div>
              </a>
              <ul
                className="collapse submenu list-unstyled"
                id="app"
                data-parent="#accordionExample"
              >
                <li>
                  <Link
                    to={{ pathname: "/admin/realUser" }}
                    className={`dropdown-toggle  my-1 ${classes.navLink}`}
                    data-toggle="collapse"
                  >
                    Real
                  </Link>
                </li>

                <li>
                  <Link
                    to={{
                      pathname: "/admin/fakeUser",
                    }}
                    className={`dropdown-toggle  my-1 ${classes.navLink}`}
                    data-toggle="collapse"
                  >
                    Fake
                  </Link>
                </li>
              </ul>
            </li>

            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/liveStreaming",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div class="">
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="512.000000pt"
                    height="512.000000pt"
                    viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="#ffff"
                      stroke="none"
                    >
                      <path
                        d="M2356 4454 c-225 -41 -448 -154 -620 -314 -68 -64 -84 -97 -65 -133
36 -66 72 -62 161 18 155 139 305 222 485 267 72 19 116 23 243 23 127 0 171
-4 243 -23 180 -45 337 -131 485 -266 89 -81 125 -85 161 -19 19 37 2 70 -73
139 -174 162 -388 268 -623 309 -106 18 -292 18 -397 -1z"
                      />
                      <path
                        d="M2375 4155 c-152 -33 -314 -118 -428 -226 -71 -67 -82 -105 -44 -143
37 -37 70 -33 120 13 174 158 330 221 549 221 105 0 120 2 138 20 30 30 27 83
-6 109 -24 19 -40 21 -148 20 -66 0 -148 -6 -181 -14z"
                      />
                      <path
                        d="M2939 4029 c-14 -14 -19 -30 -17 -57 3 -31 12 -42 69 -87 37 -28 85
-67 108 -88 46 -41 77 -46 112 -18 17 13 23 29 23 56 0 33 -7 45 -58 92 -78
73 -155 123 -188 123 -16 0 -37 -9 -49 -21z"
                      />
                      <path
                        d="M2461 3870 c-126 -27 -258 -96 -325 -170 -43 -47 -46 -81 -11 -115
36 -37 64 -31 136 27 79 63 142 93 231 109 126 23 248 -14 367 -109 72 -58
100 -64 136 -27 35 34 32 68 -11 115 -114 126 -357 205 -523 170z"
                      />
                      <path
                        d="M2469 3567 c-126 -47 -183 -131 -126 -185 33 -31 71 -28 119 11 35
28 48 32 98 32 50 0 63 -4 98 -32 49 -40 89 -43 120 -10 46 49 16 110 -79 162
-63 34 -171 44 -230 22z"
                      />
                      <path
                        d="M671 3404 c-175 -47 -318 -198 -351 -373 -5 -30 -10 -197 -10 -372
l0 -319 -101 0 c-122 0 -161 -15 -189 -71 -25 -48 -25 -86 0 -185 26 -102 96
-243 159 -320 115 -138 305 -247 473 -272 l53 -7 3 -173 2 -172 -242 0 c-275
0 -301 -6 -354 -76 -28 -36 -29 -43 -32 -158 -3 -111 -1 -125 20 -165 17 -31
38 -52 68 -68 42 -22 50 -23 337 -23 280 0 294 1 313 20 27 27 26 81 -1 106
-19 17 -44 19 -303 24 l-281 5 0 90 0 90 530 3 c292 1 540 0 553 -3 22 -5 23
-9 20 -93 l-3 -87 -121 -5 c-101 -4 -126 -8 -143 -24 -27 -25 -28 -79 -1 -106
18 -18 33 -20 150 -20 88 0 141 4 163 14 40 16 81 64 97 111 l12 35 1209 0
c1297 0 1247 -2 1302 48 49 44 52 62 57 338 l5 261 433 -250 c475 -274 487
-279 552 -235 75 50 70 -20 70 1060 0 660 -3 976 -11 995 -25 66 -93 101 -160
83 -19 -5 -226 -120 -459 -255 l-425 -245 -5 263 -5 264 -27 40 c-14 22 -43
48 -65 59 -37 18 -84 19 -1432 22 l-1394 2 -44 41 c-107 99 -281 141 -422 103z
m255 -169 c64 -31 118 -89 153 -164 26 -56 26 -57 26 -301 l0 -245 -320 0
-320 0 0 250 c0 234 1 253 21 296 40 86 118 156 207 185 62 20 168 11 233 -21z
m2973 -139 c8 -10 10 -298 9 -1072 l-3 -1059 -1205 -3 -1206 -2 -11 43 c-15
57 -47 95 -97 118 -36 16 -69 19 -283 19 l-243 0 0 170 c0 128 3 171 13 174 6
2 41 9 76 15 189 36 376 164 491 338 58 88 94 175 116 283 19 98 11 140 -36
185 -30 29 -35 30 -146 33 l-114 4 0 301 c0 273 -6 379 -26 445 l-6 22 1330 0
c1108 0 1331 -2 1341 -14z m1069 -1520 l-3 -465 -165 95 c-91 53 -171 100
-177 106 -10 8 -13 57 -13 181 0 194 -4 207 -70 207 -64 0 -74 -22 -78 -166
-2 -68 -6 -124 -9 -124 -3 0 -93 50 -199 111 l-194 112 0 401 0 401 192 110
c105 61 195 111 199 113 5 2 10 -156 11 -351 3 -333 4 -356 22 -376 25 -27 86
-29 109 -3 15 16 17 64 19 422 l3 403 175 102 175 102 3 -458 c1 -251 1 -667
0 -923z m-3861 712 c-8 -106 -20 -145 -60 -204 -89 -129 -262 -174 -403 -104
-115 56 -173 153 -182 301 l-5 89 328 0 328 0 -6 -82z m-777 -148 c23 -97 108
-211 203 -272 97 -62 258 -87 368 -58 157 43 279 159 330 314 l22 66 83 0 82
0 -14 -62 c-68 -300 -351 -513 -656 -495 -162 10 -304 75 -420 194 -91 92
-141 190 -172 336 l-6 27 84 0 84 0 12 -50z"
                      />
                      <path
                        d="M653 3068 c-46 -22 -45 -114 2 -132 9 -3 69 -6 134 -6 110 0 119 1
139 23 31 33 29 80 -4 106 -24 19 -40 21 -138 20 -61 0 -121 -5 -133 -11z"
                      />
                      <path
                        d="M640 2780 c-33 -33 -30 -75 6 -107 23 -21 35 -23 138 -23 99 0 117 3
140 21 33 26 36 79 6 109 -18 18 -33 20 -145 20 -112 0 -127 -2 -145 -20z"
                      />
                      <path
                        d="M1949 2675 c-59 -32 -59 -29 -59 -648 l0 -564 23 -34 c29 -43 81 -62
130 -48 54 15 979 546 1004 576 41 50 37 111 -10 161 -12 13 -238 147 -502
298 -514 293 -519 295 -586 259z m524 -396 c232 -133 423 -243 425 -244 1 -1
-191 -113 -428 -248 l-430 -246 0 490 c0 269 2 489 5 489 3 0 195 -109 428
-241z"
                      />
                    </g>
                  </svg>
                  <span>Live Streaming</span>
                </div>
              </Link>
            </li>

            {/* <li class="menu">
              <a
                href={() => false}
                data-toggle="collapse"
                data-active="true"
                aria-expanded="true"
                className={`dropdown-toggle`}
                onClick={handleCollapsed}
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-trello"
                  >
                    <rect
                      x="3"
                      y="3"
                      width="18"
                      height="18"
                      rx="2"
                      ry="2"
                    ></rect>
                    <rect x="7" y="7" width="3" height="9"></rect>
                    <rect x="14" y="7" width="3" height="5"></rect>
                  </svg>
                  <span>Advertisement</span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-right"
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </div>
              </a>

              <ul
                class="submenu list-unstyled collapse "
                id="forms"
                data-parent="#accordionExample"
              >
                <li>
                  <Link
                    to={{
                      pathname: "/admin/advertisement/googleAd",
                    }}
                    className={`${classes.navLink}`}
                  >
                    Google Ad
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/admin/advertisement/customAd",
                    }}
                    className={`${classes.navLink}`}
                  >
                    Custom Ad
                  </Link>
                </li>
              </ul>
            </li> */}

            <li className="menu">
              <a
                href="#form"
                data-toggle="collapse"
                className="dropdown-toggle"
              >
                <div style={{ paddingRight: "10px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-trello"
                  >
                    <rect
                      x="3"
                      y="3"
                      width="18"
                      height="18"
                      rx="2"
                      ry="2"
                    ></rect>
                    <rect x="7" y="7" width="3" height="9"></rect>
                    <rect x="14" y="7" width="3" height="5"></rect>
                  </svg>
                  <span>Advertisement</span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-right"
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </div>
              </a>
              <ul
                className="collapse submenu list-unstyled"
                id="form"
                data-parent="#accordionExample"
              >
                <li>
                  <Link
                    to={{ pathname: "/admin/advertisement/googleAd" }}
                    className={`dropdown-toggle  my-1 ${classes.navLink}`}
                    data-toggle="collapse"
                  >
                    Google Ad
                  </Link>
                </li>

                <li>
                  <Link
                    to={{
                      pathname: "/admin/advertisement/customAd",
                    }}
                    className={`dropdown-toggle  my-1 ${classes.navLink}`}
                    data-toggle="collapse"
                  >
                    Custom Ad
                  </Link>
                </li>
              </ul>
            </li>
            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/banner",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-image"
                  >
                    <rect
                      x="3"
                      y="3"
                      width="18"
                      height="18"
                      rx="2"
                      ry="2"
                    ></rect>
                    <circle cx="8.5" cy="8.5" r="1.5"></circle>
                    <polyline points="21 15 16 10 5 21"></polyline>
                  </svg>
                  <span>Banner</span>
                </div>
              </Link>
            </li>

            <li className="menu">
              <a
                href="#elements"
                data-toggle="collapse"
                className="dropdown-toggle"
              >
                <div style={{ paddingRight: "10px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-smartphone"
                  >
                    <rect
                      x="5"
                      y="2"
                      width="14"
                      height="20"
                      rx="2"
                      ry="2"
                    ></rect>
                    <line x1="12" y1="18" x2="12.01" y2="18"></line>
                  </svg>
                  <span>Reel</span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-right"
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </div>
              </a>
              <ul
                className="collapse submenu list-unstyled"
                id="elements"
                data-parent="#accordionExample"
              >
                <li>
                  <Link
                    to={{ pathname: "/admin/reel/real" }}
                    className={`dropdown-toggle  my-1 ${classes.navLink}`}
                    data-toggle="collapse"
                  >
                    Real
                  </Link>
                </li>

                <li>
                  <Link
                    to={{
                      pathname: "/admin/reel/fake",
                    }}
                    className={`dropdown-toggle  my-1 ${classes.navLink}`}
                    data-toggle="collapse"
                  >
                    Fake
                  </Link>
                </li>
              </ul>
            </li>

            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/hashtag",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-hash"
                  >
                    <line x1="4" y1="9" x2="20" y2="9"></line>
                    <line x1="4" y1="15" x2="20" y2="15"></line>
                    <line x1="10" y1="3" x2="8" y2="21"></line>
                    <line x1="16" y1="3" x2="14" y2="21"></line>
                  </svg>
                  <span>Hashtag</span>
                </div>
              </Link>
            </li>

            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/song",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-music"
                  >
                    <path d="M9 18V5l12-2v13"></path>
                    <circle cx="6" cy="18" r="3"></circle>
                    <circle cx="18" cy="16" r="3"></circle>
                  </svg>
                  <span>Song</span>
                </div>
              </Link>
            </li>
            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/gift",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-gift"
                  >
                    <polyline points="20 12 20 22 4 22 4 12"></polyline>
                    <rect x="2" y="7" width="20" height="5"></rect>
                    <line x1="12" y1="22" x2="12" y2="7"></line>
                    <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                    <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
                  </svg>
                  <span>Gift</span>
                </div>
              </Link>
            </li>
            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/sticker",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-anchor"
                  >
                    <circle cx="12" cy="5" r="3"></circle>
                    <line x1="12" y1="22" x2="12" y2="8"></line>
                    <path d="M5 12H2a10 10 0 0 0 20 0h-3"></path>
                  </svg>
                  <span>Sticker</span>
                </div>
              </Link>
            </li>
            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/redeemPlan",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-clipboard"
                  >
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                  </svg>
                  <span>Redeem Plan</span>
                </div>
              </Link>
            </li>
            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/redeem",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-dollar-sign"
                  >
                    <line x1="12" y1="1" x2="12" y2="23"></line>
                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                  </svg>
                  <span>Redeem</span>
                </div>
              </Link>
            </li>
            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/coinPlan",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div class="">
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-layers"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                    <polyline points="2 17 12 22 22 17"></polyline>
                    <polyline points="2 12 12 17 22 12"></polyline>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-target"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <circle cx="12" cy="12" r="6"></circle>
                    <circle cx="12" cy="12" r="2"></circle>
                  </svg>
                  <span>Coin Plan</span>
                </div>
              </Link>
            </li>
            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/diamondPlan",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-layers"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                    <polyline points="2 17 12 22 22 17"></polyline>
                    <polyline points="2 12 12 17 22 12"></polyline>
                  </svg>
                  <span>Diamond Plan</span>
                </div>
              </Link>
            </li>
            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/userReport",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div class="">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="css-i6dzq1"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                  </svg>
                  <span>User Report</span>
                </div>
              </Link>
            </li>
            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/setting",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-settings"
                  >
                    <circle cx="12" cy="12" r="3"></circle>
                    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                  </svg>
                  <span>Setting</span>
                </div>
              </Link>
            </li>
            <li class="menu">
              <Link
                to={{
                  pathname: "/admin/profile",
                }}
                onClick={handleToggle}
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-users"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  <span>Profile</span>
                </div>
              </Link>
            </li>
            <li class="menu">
              <a
                data-toggle="collapse"
                className={`dropdown-toggle ${classes.navLink}`}
                href
                onClick={logout}
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-log-out"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                  <span>Logout</span>
                </div>
              </a>
            </li>
          </ul>
          <div class="shadow-bottom"></div>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
