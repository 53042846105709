//Set and Unset Admin
export const SET_ADMIN = "SET_ADMIN";
export const UNSET_ADMIN = "UNSET_ADMIN";

//Update Profile and name
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_NAME = "UPDATE_PROFILE_NAME";

// signup
export const SIGNUP_ADMIN = "SIGNUP_ADMIN";
