// Get Reels
export const GET_REEL = "GET_REEL";
// Get Reels
export const GET_USER_REEL = "GET_USER_REEL";
// Get like
export const GET_LIKE = "GET_LIKE";
// Get comment
export const GET_COMMENT = "GET_COMMENT";
// Delete comment
export const DELETE_COMMENT = "DELETE_COMMENT";
// Allow Comment on  Reels
export const ALLOW_COMMENT = "ALLOW_COMMENT";
// Product Show
export const PRODUCT_SHOW = "PRODUCT_SHOW";
//delete reel
export const DELETE_REEL = "DELETE_REEL";
