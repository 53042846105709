import {
  GET_ADVERTISEMENT,
  EDIT_ADVERTISEMENT,
  SHOW_ADVERTISEMENT,
  GET_CUSTOM_AD,
  STORE_CUSTOM_AD,
  UPDATE_CUSTOM_AD,
  DELETE_CUSTOM_AD,
  ACTIVE_INACTIVE,
} from "./types";

const initialState = {
  google: null,
  customAd: [],
};

const advertisementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADVERTISEMENT:
      return {
        ...state,
        google: action.payload,
      };

    case EDIT_ADVERTISEMENT:
      return {
        ...state,
        google: action.payload,
      };

    case SHOW_ADVERTISEMENT:
      return {
        ...state,
        google: {
          ...state.google,
          show: action.payload.show,
        },
      };

    case GET_CUSTOM_AD:
      return {
        ...state,
        customAd: action.payload,
      };

    case STORE_CUSTOM_AD:
      const data = [...state.customAd];
      data.unshift(action.payload);
      return {
        ...state,
        customAd: data,
      };

    case UPDATE_CUSTOM_AD:
      return {
        ...state,
        customAd: state.customAd.map((customAd) => {
          if (customAd._id === action.payload.id) return action.payload.data;
          else return customAd;
        }),
      };

    case DELETE_CUSTOM_AD:
      return {
        ...state,
        customAd: state.customAd.filter(
          (customAd) => customAd._id !== action.payload
        ),
      };

    case ACTIVE_INACTIVE:
      return {
        ...state,
        customAd: state.customAd.map((ad) => {
          if (ad._id === action.payload._id)
            return {
              ...ad,
              show: action.payload.show,
            };
          else return ad;
        }),
      };

    default:
      return state;
  }
};

export default advertisementReducer;
