import React, { useState } from "react";
import { connect } from "react-redux";
import { updateCode } from "../Store/Admin/admin.action";

const UpdateCode = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (!email || !password || !code) {
      let error = {};
      if (!email) error.email = "Email Is Required !";
      if (!password) error.password = "password is required !";
      if (!code) error.code = "purchase code is required !";

      return setError({ ...error });
    } else {
      let login = {
        email,
        password,
        code,
      };

      props.updateCode(login);
    }
  };
  return (
    <>
      <div class="form-container outer">
        <div class="form-form">
          <div class="form-form-wrap">
            <div class="form-container">
              <div class="form-content">
                <h1 class="">Sign In</h1>
                <p class="">Log in to your account to continue.</p>

                <form class="text-left" autoComplete="off">
                  <div class="form">
                    <div id="username-field" class="field-wrapper input">
                      <label for="username">EMAIL</label>

                      <input
                        autoComplete="off"
                        id="username"
                        name="username"
                        type="text"
                        class="form-control"
                        placeholder="e.g John_Doe@gmail.com"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...error,
                              email: "Email is required !",
                            });
                          } else {
                            return setError({
                              ...error,
                              email: "",
                            });
                          }
                        }}
                      />
                      {error.email && (
                        <span style={{ color: "red" }}>{error.email}</span>
                      )}
                    </div>

                    <div id="password-field" class="field-wrapper input mb-2">
                      <div class="d-flex justify-content-between">
                        <label for="password">PASSWORD</label>
                      </div>

                      <input
                        id="password"
                        name="password"
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...error,
                              password: "Password is required !",
                            });
                          } else {
                            return setError({
                              ...error,
                              password: "",
                            });
                          }
                        }}
                      />

                      {error.password && (
                        <span style={{ color: "red" }}>{error.password}</span>
                      )}
                    </div>

                    <div id="password-field" class="field-wrapper input mb-2">
                      <div class="d-flex justify-content-between">
                        <label for="password"> PURCHASE CODE</label>
                      </div>

                      <input
                        id="code"
                        name="code"
                        type="text"
                        class="form-control"
                        placeholder="code"
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...error,
                              code: "code is required !",
                            });
                          } else {
                            return setError({
                              ...error,
                              code: "",
                            });
                          }
                        }}
                      />

                      {error.code && (
                        <span style={{ color: "red" }}>{error.code}</span>
                      )}
                    </div>

                    <div class="d-sm-flex justify-content-between">
                      <div class="field-wrapper d-flex">
                        <button
                          type="button"
                          class="btn text-white bg-primary-gradient"
                          onClick={handleSubmit}
                        >
                          Sign In
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { updateCode })(UpdateCode);
