//css
import "./assets/css/bootstrap.min.css";
import "./assets/css/plugins.css";
import "./assets/css/structure.css";
import "./assets/css/scrollspyNav.css";

//js
import "./bootstrap/js/bootstrap.min";
import "./assets/js/bootstrap.min";
import "./assets/js/popper.min";
import "./assets/js/app";
// import "./plugins/highlight/highlight.pack.js";

import { IdleTimeoutManager } from "idle-timer-manager";

//component
import Login from "./Page/Login";
import ForgetPassword from "./Page/ForgetPassword";
import AuthRoute from "./util/AuthRoute";

//react-redux-dom
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Admin from "./Page/Admin";
//react-redux
import { useDispatch, useSelector } from "react-redux";

//react
import { useEffect, useState } from "react";

//action
import { SET_ADMIN, UNSET_ADMIN } from "./Store/Admin/admin.type";

//jquery
import $ from "jquery";

import SessionExpire from "./Page/SessionExpire";
import Error404 from "./Page/Error404";
import ChangePassword from "./Page/ChangePassword";
import axios from "axios";
import Registration from "./Page/Registration";
import UpdateCode from "./Page/UpdateCode";

function App() {
  const [login, setLogin] = useState(true);
  useEffect(() => {
    axios
      .get("/login")
      .then((res) => {
        setLogin(res.data.login);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const manager = new IdleTimeoutManager({
      timeout:10 , //30 min (in sec)
      onExpired: (time) => {
        dispatch({ type: UNSET_ADMIN });
        return <Redirect to="/login" />;
      },
    });

    return () => {
      manager.clear();
    }; //eslint-disable-next-line
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      App.init();
    });
  }, []);

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const { isAuth } = useSelector((state) => state.admin);

  useEffect(() => {
    if (!token) {
      return;
    }
    dispatch({ type: SET_ADMIN, payload: token });
  }, [token, dispatch]);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          {isAuth && <Route path="/admin" component={Admin} />}

          <AuthRoute exact path="/" component={login ? Login : Registration} />
          <AuthRoute exact path="/code" component={UpdateCode} />
          <AuthRoute exact path="/" component={Login} />
          <Route exact path="/forget" component={ForgetPassword} />
          <Route exact path="/expire" component={SessionExpire} />
          <Route exact path="/changePassword/:id" component={ChangePassword} />
          <Route path="/" component={Error404} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
