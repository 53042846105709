export const GET_ADVERTISEMENT = "GET_ADVERTISEMENT";
export const EDIT_ADVERTISEMENT = "EDIT_ADVERTISEMENT";
export const SHOW_ADVERTISEMENT = "SHOW_ADVERTISEMENT";

//Get Custom Ad
export const GET_CUSTOM_AD = "GET_CUSTOM_AD";

//Insert Custom Ad
export const STORE_CUSTOM_AD = "STORE_CUSTOM_AD";

//Update Custom Ad
export const UPDATE_CUSTOM_AD = "UPDATE_CUSTOM_AD";

//Delete Custom Ad
export const DELETE_CUSTOM_AD = "DELETE_CUSTOM_AD";

//Active Inactive Switch
export const ACTIVE_INACTIVE = "ACTIVE_INACTIVE";
