export const GET_LIVE_STEAMING_VIDEO = "GET_LIVE_STEAMING_VIDEO";
export const INSERT_LIVE_STEAMING_VIDEO = "INSERT_LIVE_STEAMING_VIDEO";
export const UPDATE_LIVE_STEAMING_VIDEO = "UPDATE_LIVE_STEAMING_VIDEO";
export const DELETE_LIVE_STEAMING_VIDEO = "DELETE_LIVE_STEAMING_VIDEO";
export const GET_FAKE_USER_LIST = "GET_FAKE_USER_LIST";
export const HANDLE_LOCK_SWITCH = "HANDLE_LOCK_SWITCH";




export const OPEN_LIVE_STREAMING_DIALOG = "OPEN_LIVE_STREAMING_DIALOG";
export const CLOSE_LIVE_STREAMING_DIALOG = "CLOSE_LIVE_STREAMING_DIALOG";



