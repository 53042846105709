import { GET_REPORTED_USER } from "./report.type";

const initialState = {
  report: [],
};

const ReportedUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTED_USER:
      return {
        ...state,
        report: action.payload,
      };
    default:
      return state;
  }
};

export default ReportedUserReducer;
