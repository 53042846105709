import {
  DELETE_LIVE_STEAMING_VIDEO,
  GET_FAKE_USER_LIST,
  GET_LIVE_STEAMING_VIDEO,
  INSERT_LIVE_STEAMING_VIDEO,
  UPDATE_LIVE_STEAMING_VIDEO,
  HANDLE_LOCK_SWITCH,
  OPEN_LIVE_STREAMING_DIALOG,
  CLOSE_LIVE_STREAMING_DIALOG,
} from "./type";

const initialState = {
  liveStreaming: [],
  user: [],
  dialog: false,
  dialogData: null,
};

const LiveStreamingVideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIVE_STEAMING_VIDEO:
      ;
      return {
        ...state,
        liveStreaming: action.payload,
      };
    case GET_FAKE_USER_LIST:
      return {
        ...state,
        user: action.payload,
      };

    case INSERT_LIVE_STEAMING_VIDEO:
      const data = [...state.liveStreaming];
      data.unshift(action.payload);
      return {
        ...state,
        liveStreaming: data,
      };
    case UPDATE_LIVE_STEAMING_VIDEO:
      return {
        ...state,
        liveStreaming: state.liveStreaming.map((liveStreaming) => {
          if (liveStreaming._id === action.payload.id)
            return action.payload.data;
          else return liveStreaming;
        }),
      };

    case DELETE_LIVE_STEAMING_VIDEO:
      return {
        ...state,
        liveStreaming: state.liveStreaming.filter(
          (liveStreaming) => liveStreaming._id !== action.payload
        ),
      };
    case HANDLE_LOCK_SWITCH: 
      return {
        ...state,
        liveStreaming: state.liveStreaming.map((liveStreaming) => {
          if (liveStreaming._id === action.payload._id)
            return {
              ...liveStreaming,
              isLock : action.payload.isLock,
            }
          else return liveStreaming;
        }),
      };
    
    case OPEN_LIVE_STREAMING_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_LIVE_STREAMING_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    default:
      return state;
  }
};

export default LiveStreamingVideoReducer;
