export const GET_GIFT = "GET_GIFT";

//top switch
export const TOP_GIFT = "TOP_GIFT";

//delete gift
export const DELETE_GIFT = "DELETE_GIFT";
//edit gift
export const EDIT_GIFT = "EDIT_GIFT";
//add gift
export const CREATE_GIFT = "CREATE_GIFT";

//dialog
export const OPEN_GIFT_DIALOG = "OPEN_GIFT_DIALOG";
export const CLOSE_GIFT_DIALOG = "CLOSE_GIFT_DIALOG";
