import axios from "axios";
import { Toast } from "../../util/Toast";

import {
  GET_ADVERTISEMENT,
  EDIT_ADVERTISEMENT,
  SHOW_ADVERTISEMENT,
  GET_CUSTOM_AD,
  STORE_CUSTOM_AD,
  UPDATE_CUSTOM_AD,
  DELETE_CUSTOM_AD,
  ACTIVE_INACTIVE,
} from "./types";

export const getAdvertisement = () => (dispatch) => {
  axios
    .get("/ad")
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_ADVERTISEMENT, payload: res.data.advertisement });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error.message);
    });
};

export const editAdvertisement = (id, data) => (dispatch) => {
  axios
    .patch(`/ad/${id}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: EDIT_ADVERTISEMENT, payload: res.data.advertisement });
        Toast("success", "Updated Successfully!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error.message);
    });
};

export const showAdvertisement = (id) => (dispatch) => {
  axios
    .put(`/ad/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: SHOW_ADVERTISEMENT, payload: res.data.advertisement });
        Toast("success", res.data.advertisement.show ===true ? "Enable " :"Desable");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error.message);
    });
};

//Get Custom Ad
export const getCustomAd = () => (dispatch) => {
  axios
    .get(`customAd`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_CUSTOM_AD, payload: res.data.customAd });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

//Insert Custom Ad
export const insertCustomAd = (data) => (dispatch) => {
  axios
    .post(`/customAd/create`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "CustomAd inserted successfully!");
        dispatch({ type: STORE_CUSTOM_AD, payload: res.data.customAd });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => { console.log(error) });
};

//Update Custom Ad
export const updateCustomAd = (id, data) => (dispatch) => {
  axios
    .patch(`/customAd/update/${id}`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "CustomAd updated successfully!");
        dispatch({
          type: UPDATE_CUSTOM_AD,
          payload: { data: res.data.customAd, id: id },
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

//Delete Custom Ad
export const deleteCustomAd = (id) => (dispatch) => {
  axios
    .delete(`/customAd/delete/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_CUSTOM_AD, payload: id });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

//Active Inactive
export const isShowSwitch = (id) => (dispatch) => {
  axios
    .put(`/customAd/show/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ACTIVE_INACTIVE, payload: res.data.customAd });
        Toast("success", res.data.customAd.show == true ? "Active" : "inActive");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
